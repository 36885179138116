import React, { Component } from 'react';
import './Caret.css';

export default class Caret extends Component {
  render(props) {
    return(
      <div className={`caret-container`}>
        <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line y1="-0.5" x2="11.9262" y2="-0.5" transform="matrix(0.711485 0.702701 -0.711485 0.702701 0 0.740723)" stroke="#fff"/>
        <line y1="-0.5" x2="11.9262" y2="-0.5" transform="matrix(0.711485 -0.702701 0.711485 0.702701 9 9.24107)" stroke="#fff"/>
        </svg>
      </div>
    )
  }
}
