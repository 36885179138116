import '../Nav.css';

const { Link } = require("react-router-dom");

const AuthenticatedNav = (props) => {
 return (
   <nav className="nav authenticated-nav">
     <div>
       <Link className="app-link" to="/dashboard">
         <span className="logo">Deadicated</span> Auto Club
       </Link>
     </div>
     <div>
       <button className="button-primary logout-button" type="submit" onClick={() => props.logOut()}>Logout</button>
     </div>
   </nav>
   );
};

export default AuthenticatedNav;
