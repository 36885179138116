import React from 'react';
import { Link} from "react-router-dom";
import CompletedJob from '../../components/Jobs/CompletedJob/CompletedJob.js';
import './CompletedJobs.css';


function getCompletedDate(stepObject) {
  const lastStep = stepObject[stepObject.length - 1];
  return new Date(lastStep.finish);
}

export default function CompletedJobs() {
  const completedJobs = JSON.parse(localStorage.getItem('completedJobs'));


  return(
    <div className="wrapper">
    <div className="header">
      Completed Jobs
    </div>
    <div className="main-content">
    {completedJobs.length <=0 && (
      <p>There are no completed jobs to show.</p>
    )}
    {completedJobs.map((job) => (
      <CompletedJob jobTitle={job.make} completionDate={getCompletedDate(job.step)}></CompletedJob>
    ))}
      <div className="link-container">
        <Link className="app-link job-link" to="/dashboard" style={{ padding: "10px" }}>
          Active Jobs
        </Link>
      </div>
    </div>
  </div>
  );
}
