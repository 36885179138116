import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import './ActiveJob.css'
import Caret from '../../Caret/Caret.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



function CustomToggle({ children, target, title }) {
  const decoratedOnClick = useAccordionButton(target, (e) =>{
    const accordionWrapper = e.currentTarget.parentElement.parentElement.parentElement.parentElement;
    const clickedCaret = e.currentTarget.parentElement.querySelector('.caret-container');
    const openAccordions = accordionWrapper.querySelectorAll('.accordion-collapse.collapse.show');

    openAccordions.forEach(accordion => {
      const caretElement = accordion.parentElement.querySelector('.caret-container');
      if (caretElement.classList.contains('open') && caretElement !== clickedCaret){
        caretElement.classList.remove('open');

      }
    })

    clickedCaret.classList.toggle('open');
    }
  );

  return (
    <button
      className="custom-accordion-toggle"
      onClick={(e) => decoratedOnClick(e)}
    >
      <div className="accordion-header-toggle__container">
        <div>{capitalizeWord(title)}</div>
        <Caret/>

      </div>
    </button>
  );
}

function capitalizeWord(word) {
  return   word.charAt(0).toUpperCase() + word.slice(1);
}

function createID() {
  return Math.floor(Math.random() * 1000);
}

export default function ActiveJob({textNotifications, job, steps}) {
  const [ notifications, setNotifications ] = useState(textNotifications);

  const activeStep = steps.filter((step) => step.current)[0];

  const id = createID(job);
    
  const stepClassname = (step) => {
    if (step.current) return 'active';

    const finishedDate = new Date(step.finish);
    const today = new Date();
    if (finishedDate.getTime() < today.getTime() && finishedDate.getTime() > 0) return "complete";

    return "upcoming";
  }

  const onNotificationClick = (event) => {
    event.stopPropagation();
    event.preventDefault();

    setNotifications(!notifications);
  }

  useEffect(() => {

    const requestBody = {
      'jobType': job.type,
      'make': job.make,
      'model': job.model,
      'notifications': notifications,
    }

    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('jwt') },
      body: JSON.stringify(requestBody)
    };

    fetch('https://beta.deadicated.dev/api/v1/admin/' + localStorage.getItem('phoneNumber') + '/update/job', requestOptions)
      .then(response => {
        if (response.ok){
          
        } else {
          return this.setState({
            showRequestErrorMsg: true
          })
        }
      })

  }, [notifications, job]) 
    


  return (    
      <div className='job-wrapper'>
        <Card target={id}>
          <Card.Header>
              <CustomToggle target={id} title={job.type}></CustomToggle>
              <div className='heading'>
                <div className="status-container">
                  <div>{capitalizeWord(activeStep.name)} in progress - </div>
                  <div>Text Notifications <button className={`text-notifications ${notifications ? 'on' : 'off'}`} onClick={(event) => onNotificationClick(event)}>{notifications ? 'ON' : 'OFF'}</button></div>
                </div>
              </div>
          </Card.Header>
          <Accordion.Collapse eventKey={id}>
            <Card.Body>
              <div className="job-grid-container">
              <Container>
                {steps.map((step) => (
                  <Row className={stepClassname(step)} key={job + step.name}>
                    <Col xs={4} className="text-left g-0">{capitalizeWord(step.name)}</Col>
                    <Col xs={{ span: 4, offset: 4 }} className="text-right g-0">{stepClassname(step)}</Col>
                  </Row>
                ))}
                </Container>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </div>
  );
}
