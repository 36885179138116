const { Link } = require("react-router-dom");

const UnauthenticatedNav = () => {
 return (
   <nav lass="nav unauthenticated-nav">
     <Link className="app-link" to="/">
       <span className="logo">Deadicated</span> Auto Club
     </Link>
   </nav>
   );
};

export default UnauthenticatedNav;
