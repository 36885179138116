import React, { Component } from 'react';
import { Link } from "react-router-dom";
import ActiveJob from '../../components/Jobs/ActiveJob/ActiveJob.js';
import Accordion from 'react-bootstrap/Accordion';
import './Dashboard.css';
import Cookies from 'universal-cookie';

export default class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      phoneNum: '',
      jwt: '',
      activeJobs: [],
      completedJobs: [],
      showRequestErrorMsg: false
    };
    

    this.handleLoad = this.handleLoad.bind(this);
  }

  componentDidMount() {
    console.log('running preload')

    const phoneNum = localStorage.getItem('phoneNumber')

    const cookies = new Cookies();
    localStorage.setItem('jwt', cookies.get('Authorization'));
    const jwt = localStorage.getItem('jwt')


    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': jwt },
    };
    fetch('https://beta.deadicated.dev/api/v1/status/' + phoneNum, requestOptions)
      .then(response => {
        console.log('response status', response.status);
        if (response.ok){
          response.json().then(respJson => {
            this.setState({
              activeJobs: respJson.activeJobs
            });
            localStorage.setItem('completedJobs', JSON.stringify(respJson.completedJobs));
          })

        } else {
          return this.setState({
            showRequestErrorMsg: true
          })
        }
      })

    window.addEventListener('load', this.handleLoad);
  }

  componentWillUnmount() { 
    window.removeEventListener('load', this.handleLoad)  
  }

  handleLoad() {
    //$("myclass") //  $ is available here
  }

  render() {
    const { activeJobs } = this.state;
    return(
      <div className="wrapper">
        <div className="header">
          Job Tracker
        </div>
        <div className="main-content">
          <Accordion defaultActiveKey="100">
            {activeJobs.map((item, index) =>
              <ActiveJob key={index} textNotifications={item.notifications} job={item} steps={item.step} />
            )}
          </Accordion>
          <div className="link-container">
            <Link className="app-link job-link" to="/completed-jobs" style={{ padding: "10px" }}>
              Previous Jobs
            </Link>
          </div>
        </div>
      </div>
    )
  }
}
