import React, { Component } from 'react';
import '../ActiveJob/ActiveJob.css';

export default class CompletedJob extends Component {
  render(props) {
    return(
      <div className="completed-job-container">
        <div className="job-details">
          <div className="job-title">
            {this.props.jobTitle}
          </div>
          <div className="job-date status-container">
            <span>Completed on</span>{'  '}{this.props.completionDate}
          </div>
        </div>
      </div>
    )
  }
}
