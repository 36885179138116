import './Home.css';
import React, { Component } from 'react';
import Cookies from 'universal-cookie';

export default class Home extends Component {
  constructor(props) {
      super(props);
      this.state = {
        showOTPField: false,
        phoneNumber: '',
        OTP: '',
        phoneNumberErrorMsg: false,
        OTPErrorMsg: false,
        showRequestErrorMsg: false,
        isFetching: false
      };

    this.handleInputChange = this.handleInputChange.bind(this);
    }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const errorMessageStateName = `${target.name}ErrorMsg`;

    this.setState({
      [name]: value,
      [errorMessageStateName]: false,
      showRequestErrorMsg: false
    });
  }

  formatPhoneNumber(phoneNum){
      var cleaned = ('' + phoneNum).replace(/\D/g, '');
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
      return "";
  }

  validatePhoneNumber(){
    let phoneNum = this.state.phoneNumber;
    if (phoneNum) {
      if (isNaN(phoneNum)){
        this.setState({phoneNumberErrorMsg: true});
        return false;
      } else {
        if (phoneNum.length === 10){
          return true
        } else {
          this.setState({phoneNumberErrorMsg: true});
          return false
        }
      }
    } else {
      this.setState({phoneNumberErrorMsg: true});
      return false
    }
  }

  getPhoneNumberPlaceholder(){
    if (localStorage.getItem('phoneNumber')){
      return this.formatPhoneNumber(localStorage.getItem('phoneNumber'));
    } else return '(XXX) XXX-XXXX';
  }

  submitPhoneNumber = async (e) => {
    e.preventDefault();

    if (!this.validatePhoneNumber()){
      return this.setState({phoneNumberErrorMsg: true});
    } else {
      this.setState({isFetching: true});
      localStorage.clear();
      localStorage.setItem('phoneNumber', this.state.phoneNumber);

      const requestOptions = {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "phoneNum": this.state.phoneNumber
        }),
      };
      fetch("https://beta.deadicated.dev/api/v1/login/new", requestOptions)
          .then(response => {
            console.log('response status', response.status);
            if (response.ok){
              return this.setState({
                'showOTPField':true,
                isFetching: false })
            } else {
              return this.setState({
                showRequestErrorMsg: true,
                isFetching: false
              });
            }
          })
      }
    };


  submitOTP = async (e) => {
    e.preventDefault();
    this.setState({isFetching: true});

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({phoneNum: localStorage.getItem('phoneNumber'), code: this.state.OTP })
    };
    fetch('https://beta.deadicated.dev/api/v1/login/auth', requestOptions)
      .then(response => {
        console.log('response status', response.status);
        if (response.ok){
          this.setState({isFetching: false})

          response.text().then(respText => {
            const cookies = new Cookies();
            cookies.set('Authorization', respText, { path: '/' });
          })
          
          return this.props.login();
        } else {
          return this.setState({
            showRequestErrorMsg: true,
            isFetching: false
          })
        }
      })
  };

  

  renderPhoneNumberForm(){
    return (
      <div className="wrapper">
        <form onSubmit={(e) => {this.submitPhoneNumber(e)}}>
          <div className="form-field__container label-container">
            <label className="form-label">Enter Phone Number</label>
          </div>
          <div className="error-message">
            <div>{this.state.phoneNumberErrorMsg ? 'Please enter a valid phone number.' : null}</div>
            <div>{this.state.showRequestErrorMsg ? 'Request failed. Please try again.' : null}</div>
          </div>
          <div className="form-field__container input-container">
            <input  className="form-text-field" type="tel" placeholder="(XXX) XXX-XXXX" name="phoneNumber" autoComplete="off" maxLength="10" value={this.state.phoneNumber} onChange={this.handleInputChange}></input>
          </div>
         
          <div className="form-field__container button-container">
            <button className={`button-primary form-submit ${this.state.isFetching ? 'fetching' : null}`} type="submit">Send Code</button>
          </div>
        </form>
      </div>
    )
  }

  renderOTPForm(){
    return(
      <div className="wrapper">
        <form onSubmit={(e) => this.submitOTP(e)}>
          <div className="form-field__container label-container">
            <label className="form-label">Enter Passcode</label>
          </div>
          <div className="error-message">
            <div>{this.state.phoneNumberErrorMsg ? 'Please enter a valid phone number.' : null}</div>
            <div>{this.state.showRequestErrorMsg ? 'Request failed. Please try again.' : null}</div>
          </div>
          <div className="form-field__container input-container">
            <input className="form-text-field" type="text" placeholder="XXX-XXX" name="OTP" maxLength="6" value={this.state.OTP} onChange={this.handleInputChange}></input>
          </div>
          <div className="form-field__container helper-text">
            <label className="form-helper-text">Code sent to {this.getPhoneNumberPlaceholder()}</label>
          </div>
          
          <div className="form-field__container button-container">
            <button className={`button-primary form-submit ${this.state.isFetching ? 'fetching' : null}`} type="submit">Submit Code</button>
          </div>
        </form>
      </div>
    )
  }


  render() {
    const {showOTPField} = this.state;

    return(
      <div className="wrapper">
        <div className="header">
          Job Tracker
        </div>
        <div className="main-content">
          <div className="content__vertical-center">
          {showOTPField ? (
            this.renderOTPForm()
          ) : (
            this.renderPhoneNumberForm()
          )}
          </div>
        </div>
      </div>
    )
  }
}