import './App.css';
import { Routes, Route } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import AuthenticatedNav from "./components/Nav/AuthenticatedNav/AuthenticatedNav";
import UnauthenticatedNav from "./components/Nav/UnauthenticatedNav/UnauthenticatedNav";
import Protected from "./routes/Protected/Protected";
import Home from "./routes/Home/Home";
import CompletedJobs from "./routes/CompletedJobs/CompletedJobs";
import Dashboard from "./routes/Dashboard/Dashboard";

function App() {
  const navigate = useNavigate();

   const [isLoggedIn, setisLoggedIn, loginToken, setLoginToken] = useState(null);
   const logIn = () => {
     setisLoggedIn(true);
     navigate("/dashboard");
   };
   const logOut = () => {
     setisLoggedIn(false);
     localStorage.clear();
     navigate('/');
   };

   return (
     <div className="content">
       {isLoggedIn ? (
         <AuthenticatedNav logOut={logOut}/>
       ) : (
         <div>
           <UnauthenticatedNav/>
         </div>
       )}

       <Routes>
         <Route path='/' element={<Home login={logIn}/>} />

         <Route path='/dashboard'
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <Dashboard />
              </Protected>
             }
           />

         <Route path ='/completed-jobs' 
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <CompletedJobs />
              </Protected>
            }
         />
       </Routes>
     </div>
   );
}

export default App;
